@import "~shared/theme/scss/bootstrap/functions";
@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins/grid";

@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/container";

@import "~bootstrap/scss/grid";

@if $enable-cssgrid {
  .grid {
    @include make-cssgrid-columns();

    > * {
      min-width: 0;
    }
  }
}

.main-and-sidebar-container {
  grid-template-columns: 100%;
}

@include media-breakpoint-up(lg) {
  .main-and-sidebar-container--end:not(.main-and-sidebar-container--stretch:not(:has(main + div))) {
    grid-template-columns: minmax(0, 1fr) var(--pr-sidebar-width, 300px);
  }

  .main-and-sidebar-container--start:not(.main-and-sidebar-container--stretch:not(:has(main + div))) {
    grid-template-columns: var(--pr-sidebar-width, 300px) minmax(0, 1fr);
  }
}

.single-centered-container {
  max-width: calc(var(--pr-single-centered-container-max-width, 800px) + #{$grid-gutter-width});
}
